import './App.css';
import {UnicornConfigContext} from './UnicornConfigProvider';
import {useContext} from 'react';
import {GetNetworkConfigurationResponseProto} from './compiled';

function App() {

  /**
   * @type {GetNetworkConfigurationResponseProto}
   */
  const unicornConfig = useContext(UnicornConfigContext);

  return (
      <div className="App">
        <div className={'Header'}>
          <div className={'HeaderLeftElement'}>
            {unicornConfig.networkName}
          </div>
          <div className={'HeaderRightElement'}>
            Text 1
          </div>
          <div className={'HeaderRightElement'}>
            Text 2
          </div>
          <div className={'HeaderRightElement'}>
            Text 3
          </div>
          <div className={'HeaderRightElement InvertedColors'}>
            CTA
          </div>

        </div>

        <div className={'HeadlineText'}>
          {unicornConfig.networkDescription}
        </div>

        <div style={{height: 300}}/>
          
        <div className={'Footer}'}>
          copyright 2024 {unicornConfig.networkName}
          <br/>
          <a href={'provider/'}>provider login</a>
          
        </div>
      </div>
  );
}

export default App;
