import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {UnicornConfigProvider} from './UnicornConfigProvider';

import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {ProviderHome} from './provider/ProviderHome';
import {Auth0Provider} from '@auth0/auth0-react';
import {MyGraphiQL} from './graphql/MyGraphiQL';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
  },
  {
    path: '/provider/',
    element: <ProviderHome/>,
  },
  {
    path: '/graphiql/',
    element: <MyGraphiQL/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <Auth0Provider
          domain="dev-ffld3400rslvg3a0.us.auth0.com"
          clientId="lQCbipNvxtMxanWhL2gPbCn4UpKEiVC0"
          authorizationParams={{
            redirect_uri: window.location.origin
          }}>
        <UnicornConfigProvider>
          <RouterProvider router={router}/>
        </UnicornConfigProvider>
      </Auth0Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
