import React, {useContext} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {UnicornConfigContext} from '../UnicornConfigProvider';
import './Provider.css';
import {LeftNavIcon} from './LeftNavIcon';

export const ProviderHome = () => {

  /**
   * @type {GetNetworkConfigurationResponseProto}
   */
  const unicornConfig = useContext(UnicornConfigContext);

  const {user, isAuthenticated, isLoading, loginWithRedirect} = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    return (
        <div>
          <button onClick={() => loginWithRedirect({

            appState: {
              returnTo: '/provider',
            },
          })}>Log In
          </button>
          to access the provider portal.
        </div>
    );
  }

  // Loaded and authenticated.
  return (
      <div className="App">

        <div className={'Header'}>
          <div className={'HeaderLeftElement'}>
            {unicornConfig.networkName} provider
          </div>
          <div className={'HeaderRightElement'}>
            logged in as <strong>{user.email}</strong>
          </div>
        </div>

        <div className={'Body'}>

          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>

            <div style={{display: 'flex', flexDirection: 'column', flex: 0}}>
              <LeftNavIcon>L</LeftNavIcon>
              <LeftNavIcon>E</LeftNavIcon>
              <LeftNavIcon>F</LeftNavIcon>
              <LeftNavIcon>T</LeftNavIcon>
              <LeftNavIcon> </LeftNavIcon>
              <LeftNavIcon>N</LeftNavIcon>
              <LeftNavIcon>A</LeftNavIcon>
              <LeftNavIcon>V</LeftNavIcon>
            </div>

            <div style={{flex: 1}}>
              Welcome to your {unicornConfig.networkName} provider
              page, {user.nickname}!
            </div>

          </div>

        </div>
      </div>
  );
};