import {createGraphiQLFetcher} from '@graphiql/toolkit';
import {GraphiQL} from 'graphiql';
import React from 'react';
import 'graphiql/graphiql.css';

export const MyGraphiQL = () => {
  const fetcher = createGraphiQLFetcher({url: '/graphql'});
  return (
      <div style={{width: '100%', height: '100%', position: 'absolute'}}>
        <GraphiQL fetcher={fetcher}/>
      </div>
  );
};