/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.GetNetworkConfigurationRequestProto = (function() {

    /**
     * Properties of a GetNetworkConfigurationRequestProto.
     * @exports IGetNetworkConfigurationRequestProto
     * @interface IGetNetworkConfigurationRequestProto
     */

    /**
     * Constructs a new GetNetworkConfigurationRequestProto.
     * @exports GetNetworkConfigurationRequestProto
     * @classdesc Represents a GetNetworkConfigurationRequestProto.
     * @implements IGetNetworkConfigurationRequestProto
     * @constructor
     * @param {IGetNetworkConfigurationRequestProto=} [properties] Properties to set
     */
    function GetNetworkConfigurationRequestProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetNetworkConfigurationRequestProto instance using the specified properties.
     * @function create
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto=} [properties] Properties to set
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto instance
     */
    GetNetworkConfigurationRequestProto.create = function create(properties) {
        return new GetNetworkConfigurationRequestProto(properties);
    };

    /**
     * Encodes the specified GetNetworkConfigurationRequestProto message. Does not implicitly {@link GetNetworkConfigurationRequestProto.verify|verify} messages.
     * @function encode
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationRequestProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetNetworkConfigurationRequestProto message, length delimited. Does not implicitly {@link GetNetworkConfigurationRequestProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationRequestProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetNetworkConfigurationRequestProto message from the specified reader or buffer.
     * @function decode
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationRequestProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetNetworkConfigurationRequestProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetNetworkConfigurationRequestProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationRequestProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetNetworkConfigurationRequestProto message.
     * @function verify
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetNetworkConfigurationRequestProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetNetworkConfigurationRequestProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     */
    GetNetworkConfigurationRequestProto.fromObject = function fromObject(object) {
        if (object instanceof $root.GetNetworkConfigurationRequestProto)
            return object;
        return new $root.GetNetworkConfigurationRequestProto();
    };

    /**
     * Creates a plain object from a GetNetworkConfigurationRequestProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {GetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetNetworkConfigurationRequestProto.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetNetworkConfigurationRequestProto to JSON.
     * @function toJSON
     * @memberof GetNetworkConfigurationRequestProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetNetworkConfigurationRequestProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetNetworkConfigurationRequestProto
     * @function getTypeUrl
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetNetworkConfigurationRequestProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetNetworkConfigurationRequestProto";
    };

    return GetNetworkConfigurationRequestProto;
})();

$root.GetNetworkConfigurationResponseProto = (function() {

    /**
     * Properties of a GetNetworkConfigurationResponseProto.
     * @exports IGetNetworkConfigurationResponseProto
     * @interface IGetNetworkConfigurationResponseProto
     * @property {boolean|null} [okay] GetNetworkConfigurationResponseProto okay
     * @property {string|null} [networkName] GetNetworkConfigurationResponseProto networkName
     * @property {string|null} [networkDescription] GetNetworkConfigurationResponseProto networkDescription
     */

    /**
     * Constructs a new GetNetworkConfigurationResponseProto.
     * @exports GetNetworkConfigurationResponseProto
     * @classdesc Represents a GetNetworkConfigurationResponseProto.
     * @implements IGetNetworkConfigurationResponseProto
     * @constructor
     * @param {IGetNetworkConfigurationResponseProto=} [properties] Properties to set
     */
    function GetNetworkConfigurationResponseProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetNetworkConfigurationResponseProto okay.
     * @member {boolean} okay
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.okay = false;

    /**
     * GetNetworkConfigurationResponseProto networkName.
     * @member {string} networkName
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.networkName = "";

    /**
     * GetNetworkConfigurationResponseProto networkDescription.
     * @member {string} networkDescription
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.networkDescription = "";

    /**
     * Creates a new GetNetworkConfigurationResponseProto instance using the specified properties.
     * @function create
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto=} [properties] Properties to set
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto instance
     */
    GetNetworkConfigurationResponseProto.create = function create(properties) {
        return new GetNetworkConfigurationResponseProto(properties);
    };

    /**
     * Encodes the specified GetNetworkConfigurationResponseProto message. Does not implicitly {@link GetNetworkConfigurationResponseProto.verify|verify} messages.
     * @function encode
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationResponseProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.networkName != null && Object.hasOwnProperty.call(message, "networkName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.networkName);
        if (message.networkDescription != null && Object.hasOwnProperty.call(message, "networkDescription"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.networkDescription);
        return writer;
    };

    /**
     * Encodes the specified GetNetworkConfigurationResponseProto message, length delimited. Does not implicitly {@link GetNetworkConfigurationResponseProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationResponseProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetNetworkConfigurationResponseProto message from the specified reader or buffer.
     * @function decode
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationResponseProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetNetworkConfigurationResponseProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.networkName = reader.string();
                    break;
                }
            case 3: {
                    message.networkDescription = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetNetworkConfigurationResponseProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationResponseProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetNetworkConfigurationResponseProto message.
     * @function verify
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetNetworkConfigurationResponseProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.networkName != null && message.hasOwnProperty("networkName"))
            if (!$util.isString(message.networkName))
                return "networkName: string expected";
        if (message.networkDescription != null && message.hasOwnProperty("networkDescription"))
            if (!$util.isString(message.networkDescription))
                return "networkDescription: string expected";
        return null;
    };

    /**
     * Creates a GetNetworkConfigurationResponseProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     */
    GetNetworkConfigurationResponseProto.fromObject = function fromObject(object) {
        if (object instanceof $root.GetNetworkConfigurationResponseProto)
            return object;
        var message = new $root.GetNetworkConfigurationResponseProto();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.networkName != null)
            message.networkName = String(object.networkName);
        if (object.networkDescription != null)
            message.networkDescription = String(object.networkDescription);
        return message;
    };

    /**
     * Creates a plain object from a GetNetworkConfigurationResponseProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {GetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetNetworkConfigurationResponseProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.networkName = "";
            object.networkDescription = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.networkName != null && message.hasOwnProperty("networkName"))
            object.networkName = message.networkName;
        if (message.networkDescription != null && message.hasOwnProperty("networkDescription"))
            object.networkDescription = message.networkDescription;
        return object;
    };

    /**
     * Converts this GetNetworkConfigurationResponseProto to JSON.
     * @function toJSON
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetNetworkConfigurationResponseProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetNetworkConfigurationResponseProto
     * @function getTypeUrl
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetNetworkConfigurationResponseProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetNetworkConfigurationResponseProto";
    };

    return GetNetworkConfigurationResponseProto;
})();

module.exports = $root;
