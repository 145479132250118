import {createContext, useEffect, useState} from 'react';
import {GetNetworkConfigurationResponseProto} from './compiled';

export const UnicornConfigContext = createContext(
    new GetNetworkConfigurationResponseProto);

/**
 * This should return the proto configuration for this unicorn.
 * It contains all the unicorn's special characteristics, like
 * the logo, description, which modules are on the website, etc.
 *
 *
 * @return {JSX.Element}
 * @constructor
 */
export const UnicornConfigProvider = ({children}) => {

  const [unicornConfig, setUnicornConfig] = useState(
      new GetNetworkConfigurationResponseProto({
      }));

  useEffect(() => {
    // Download it immediately at the beginning...
    console.log('[Downloading unicorn config...]');

    fetch('/action/1').
        then(response => response.arrayBuffer()).
        then(bytes => {
          console.log('response', new TextDecoder().decode(bytes))
          setUnicornConfig(GetNetworkConfigurationResponseProto.decode(
              new Uint8Array(bytes)));
        }).
        catch(error => {
          console.error(error);
          setUnicornConfig(new GetNetworkConfigurationResponseProto({
            networkName: 'network config error!',
            networkDescription: error.message,
          }));
        });

  }, [] /* never changes */);

  return (<UnicornConfigContext.Provider value={unicornConfig}>
    {children}
  </UnicornConfigContext.Provider>);
};
